.formFormGroup {
  width: 250px;
  margin: 0px 8px;
  margin-bottom: 16px !important;
}

.formInputGroup {
  width: 250px !important;
}

.rs-form-control-wrapper {
  display: block !important;
}

/* .formInput {
  width: 100% !important;
  height: 38px;
  color: #575757 !important;
  border-radius: 5px !important;
  border: 1px solid #808080 !important;
  z-index: 0 !important;
} */

.formInputError {
  color: #cf1e1e !important;
  height: 0px !important;
  margin: 0px !important;
}

.formCheckbox {
  position: initial !important;
  display: flex !important;
  align-items: center !important;
}

.formCheckboxText {
  margin-left: 5px;
  font-size: 17px;
}

.selectInputContainer {
  width: 100%;
  cursor: pointer !important;
}

.rs-picker-default .rs-picker-toggle {
  z-index: 0 !important;
}

.rs-input {
  width: auto;
}
