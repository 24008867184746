.buttonThemeContainer {
  position: fixed;
  right: 70px;
  top: 7px;
}

.buttonTheme {
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 5px;
}

.buttonTheme_theme_pink {
  background: linear-gradient(180deg, #943356 0%, #6d5e9c 100%);
}

.buttonTheme_theme_blue {
  background: #0068ff;
}
