.footerContainer {
  bottom: 0;
  padding: 16px;
  position: fixed;
  display: flex;
  align-items: center;
  width: calc(100% - 62px);
  justify-content: space-between;
  background-color: #f5f8ff;
  z-index: 6;
}

.footerLogo {
  height: 24px;
  filter: brightness(0.1);
  opacity: 0.3;
}

.footerText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 16px;
}

.footerItem {
  padding: 0 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 800px) {
  .footerContainer {
    width: 100%;
  }
}
