.container {
  min-width: 100%;
}

.navPrincipal {
  display: flex;
  flex-wrap: wrap;
  height: 50px;
}

.navCol {
  display: flex;
  justify-content: center;
  background-color: #dbdbdb;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
  border: solid;
  border-color: #fff;
}

.subContainer {
  border-radius: 5px;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
}

.btn-clear {
  max-height: 38px;
  margin-right: 20px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #cc0000 !important;
  color: #cc0000 !important;
  word-wrap: break-word;
}

.iconClose {
  padding: 8px 0px;
  width: "10%";
}

.nameReport {
  width: 90%;
  padding: 8px 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.navs.rs-nav-item .rs-nav-item-content {
  padding: 0px !important;
}
