.pageContainer {
  width: 100%;
  margin: 16px 0 72px;
  padding: 0 16px;
  overflow: hidden;
  min-height: 100vh;
}

.pageButtonBlue {
  background-color: var(--loginButton) !important;
  border: 1px solid var(--loginButton) !important;
}

.pageButton,
.pageButtonBlue {
  width: 100px;
  border-radius: 7px !important;
  margin: 10px;
  font-size: 10pt !important;
  font-weight: bold !important;
}

.pageButtonGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.pageFormContainer {
  width: 100%;
  display: flex;
}

.pageForm {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pageFlex {
  display: flex;
  justify-content: center;
}

.pageContent {
  width: 100%;
  max-width: 2000px;
  margin-right: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px #00000030;
  background: #ffffff;
  padding: 16px;
  border-radius: 10px !important;
}

.pageContent.w650 {
  width: 650px;
  max-width: 100%;
}

.pageContent.w850 {
  width: 850px;
  max-width: 100%;
}

.pageContent.w1050 {
  width: 1050px;
  max-width: 100%;
}

.pageContent.w2000 {
  width: 2000px;
  max-width: 100%;
}

.pageContent.withoutSearch {
  margin-right: 0;
}

.pageLabelAutocomplete {
  margin: 10px 0px 0px 0px;
}

.rs-picker-toggle-value {
  color: #575757 !important;
}

.primaryForm {
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
  overflow: hidden;
  padding-right: 5%;
}

.rowAlign {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 700px) {
  .primaryForm {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
@media (min-width: 700px) {
  .primaryFormContainer {
    min-height: 200px;
  }
  .spliter {
    height: 200px;
    width: 2px;
    background-color: lightgrey;
  }
}

.photoPicker {
  background-color: #f5f5f5;
  height: 205px;
  width: 255px;
  border: 2px dashed lightblue;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.photoPicker:hover {
  opacity: 0.6;
}

.margin-bottom {
  margin-bottom: 16px;
}

.gridInputMask {
  width: 100%;
  height: 31px;
  border: none;
}
.gridInputMask:active,
.gridInputMask:focus {
  border: none;
  outline: transparent;
}
#preview {
  height: 200px;
  width: 250px;
}

.rs-input {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uppy-DashboardContent-panelBody {
  max-height: 450px !important;
}

.disabledCheck .rs-checkbox-wrapper {
  cursor: not-allowed !important;
}

.inputMaskCurrency {
  width: 100%;
  height: 31px;
  border: none !important;
}
.inputMaskCurrency:active,
.inputMaskCurrency:focus {
  border: none;
  outline: transparent;
}
