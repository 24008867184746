.cardContainer {
  width: 400px;
  height: 500px;
  padding: 15px 10px 15px 10px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 0px 50px 10px rgba(0,0,0,0.1);
}

.cardPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}