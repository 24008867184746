.homeContainer {
  display: flex;
  justify-content: center;
}
.shortcutContainer {
  width: 80%;
  max-width: 100%;
}

.shortcutCards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 70px;
}

.cardIcon {
  border-radius: 50px;
  height: 80px;
  width: 80px;
  padding: 25px;
  align-items: center;
  justify-content: center;
  background-color: #f5f8ff;
}

.cardShortcut {
  display: flex;
  margin: 8px;
  padding: 16px;
  min-width: 200px;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: #eeee 0px 0px 10px;
  transition: transform 500ms ease;
}

.cardShortcut span {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.cardShortcut:hover {
  transform: scale(1.1);
  text-decoration: none;
}

.cardShortcut:active {
  background-color: #e0e0e0;
}

.title {
  margin: 25px;
}

.icon {
  color: var(--sidebarBackground);
}
