/* Menu */
@media (max-width: 800px) {
  .sideBarContainer {
    display: none;
  }
}

.sideBarContainer {
  left: 0;
  top: 0;
  z-index: 7;
  width: 60px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  color: #ffffff;
  overflow: hidden;
  transition: 750ms cubic-bezier(0, 1, 0.5, 1);
  background-color: var(--sidebarBackground);
}

.sideBarScroll {
  max-height: 100%;
  overflow-y: scroll;
  padding-bottom: 180px;
  overflow-x: hidden;
}

.displayBlock {
  display: block !important;
}

.displayNone {
  display: none;
}

.sideBarContainer.show {
  width: 300px;
}

.sideBarContainer .sideBarItem {
  padding: 16px;
  display: flex;
  color: #ffffff;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 500ms ease-in;
}

.sideBarContainer .sideBarItem:hover {
  background-color: var(--sidebarSelect);
}

.sideBarContainer span.navbar__brand {
  flex: 1;
  display: none;
  margin-left: 16px;
  transition: color 500ms ease;
}

.sideBarContainer span.navbar__brand:hover {
  color: #bdbdbd;
}

.sideBarContainer .sideBarItem .arrow {
  opacity: 0;
  display: none;
}

.sideBarContainer .sideBarItem:hover .arrow {
  opacity: 1;
  display: inline-block;
}

.selectedSubmenuArrow {
  transform: rotate(-180deg);
  transition: all 0.3s ease-in;
}

.submenu {
  height: 0;
  opacity: 0;
  transition: 200ms ease-in-out;
}

.submenu.show {
  height: auto;
  opacity: 1;
}

.sideBarLogoContainer {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sideBarLogo {
  height: 44px;
  min-width: 44px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.sideBarLogo.logoOpen {
  width: 100%;
  background-image: url(../../assets/images/logo-light.png);
}
.sideBarLogo.logoClose {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url(../../assets/images/logo-short.svg);
}

.sideBarSubMenuItem {
  background-color: rgba(255, 255, 255, 0.3);
}

.sideBarItemSelected {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.out {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--sidebarBackground);
}
