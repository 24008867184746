.theme_pink {
  --sidebarBackground: #943356;
  --sidebarFontColor: #943356;
  --sidebarSelect: #5f2137;
  --loginButton: #aa0b0b;
}

.theme_blue {
  --sidebarBackground: #007bff;
  --sidebarFontColor: #0248af;
  --sidebarSelect: #0a5eb8;
  --loginButton: #0655c7;
}

.appContainer {
  background-color: #f5f8ff;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.routerContainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 0 0 62px;
  width: calc(100vw - 10px);
  overflow-x: hidden;
}

@media (max-width: 820px) {
  .routerContainer {
    margin: 0 5px !important;
  }
}

@media (min-width: 821px) {
  .routerContainer {
    margin: 0 12px 0 62px;
  }
}

.rs-tooltip {
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  z-index: 1 !important;
}

.rs-tooltip .rs-tooltip-inner {
  color: #f44633 !important;
  background-color: #fff !important;
}

.rs-tooltip-arrow {
  border-bottom-color: #fff !important;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.2));
}

div.rs-modal-wrapper {
  width: 100% !important;
  min-height: 100% !important;
}

.rs-modal {
  height: 100%;
  overflow-y: scroll;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uppy-Dashboard-isFixed {
  overflow-y: auto !important;
}

/* Toat Modifications */
.react-toast-notifications__container.css-nvf14r-ToastContainer {
  z-index: 1051;
}

/* RSUITE Disabled Modifications */
.rs-picker-disabled {
  opacity: 1 !important;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 2;
}
