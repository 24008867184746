@media (max-width: 700px) {
  .filterMain {
    position: fixed;
    z-index: 10;
  }
  .filterContainer {
    width: 300px !important;
  }
  .filterButton {
    float: right;
    right: 5px !important;
  }
}

.filterContainer {
  display: flex;
  float: right;
  overflow-y: auto;
  position: fixed;
  box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.17);
  height: 100%;
  right: 19px;
  justify-content: center;
  background-color: #ffffff;
  position: fixed;
  box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.17);
  height: 100%;
  right: 19px;
  border-radius: 10px;
  border: 1px solid #b1b1b1;
}

.filterTitle {
  font-size: 14pt;
  margin: 10px 30px;
}

.filterButton {
  z-index: 1;
  height: 50px;
  width: 110px;
  position: fixed;
  right: 20px;
  padding: 8px !important;
  border-radius: 8px !important;
  border: 1px solid #b1b1b1 !important;
  background-color: #ffffff !important;
}

@keyframes show {
  from {
    width: 0px;
  }
  to {
    width: 310px;
  }
}

@keyframes hide {
  from {
    width: 310px;
  }
  to {
    width: 0px;
  }
}

.filterShow {
  animation: show 0.4s ease forwards;
}

.filterHide {
  animation: hide 0.4s ease forwards;
  border: none !important;
}

/* Filter Modal Modification */
div.rs-modal-body {
  padding-bottom: 0;
}
