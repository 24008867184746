.headerContainer {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 60px;
  margin: 6px 0px 16px 0px;
  justify-content: space-around;
  background-color: #f5f8ff;
  padding-bottom: 5px;
}

.containerTabs {
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: 100%;
  padding: 0px 10px;
  border-radius: 16px;
  margin-top: 5px;
  padding-top: 5px;
  overflow: hidden;
}

.altContainerNoTabs {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: 100%;
  padding: 0px 10px;
  border-radius: 16px;
  margin-top: 5px;
  padding-top: 5px;
}

.tabs {
  display: flex;
  width: 95%;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
}
.headerTabs {
  display: inline-block;
  font-size: 18px;
  min-width: 200px;
  height: 90%;
  padding: 5px 15px 0px 15px;
  border-radius: 6px 6px 0px 0px;
  transition: 300ms ease;
  margin: 4.5px 0px 0px 10px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-bottom: none;
}
.headerTabs div b.description {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll:hover {
  overflow-x: auto;
}

.headerTabs:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.headerContainer .hero {
  min-width: 44px;
  height: 44px;
  background-color: #fff !important;
  border-radius: 50%;
  margin: 6px;
  align-items: center;
  justify-content: center;
  margin: 6px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.17);
  border: none;
}

button.hero.center.dropdown-toggle.btn.btn-secondary {
  color: #000;
}

button.headerUserButton {
  color: #000;
  margin: 8px;
  border-radius: 30px;
  border-color: #eeeeee !important;
  background-color: #eeeeee !important;
}

button.buttonGoOut,
div.buttonGoOut {
  display: flex;
  min-width: 44px !important;
  height: 44px;
  padding: 6px;
  border: none;
  margin: 6px;
  min-width: 36px;
  background-color: #fff !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.17);
}

.headerMargin {
  margin-left: 16px;
}

.dropdownMenuContainar {
  margin-right: 50px !important;
  z-index: 11 !important;
}
