.loginPage {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/Bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.loginContainer {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginInputGroup {
  width: 300px !important;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin: 20px 0px 40px 0px !important;
  margin-bottom: 40px !important;
}

.loginInput {
  width: 250px !important;
  background-color: #e9ecef !important;
}

.loginInputUser {
  border-radius: 8px !important;
}

.loginInputPassword {
  width: 210px !important;
  border-radius: 8px 0px 0px 8px !important;
}

.loginImage {
  margin-bottom: 15px;
  width: 150px;
}

.loginButton {
  width: 200px;
  border-radius: 7px !important;
  font-size: 12pt !important;
  font-weight: bold !important;
  background-color: var(--loginButton) !important;
  border: 1px solid var(--loginButton) !important;
}

.loginButtonGroup {
  display: flex;
  justify-content: center;
}

.show-password {
  width: 40px;
  height: 36px;
  padding: 0px !important;
  border-radius: 0px 8px 8px 0px !important;
  background-color: var(--loginButton) !important;
  border: 1px solid var(--loginButton) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.loginError {
  position: fixed;
  text-align: center;
  font-size: 13pt;
  color: #db2424;
  width: 300px;
}

.loginIcon {
  width: 30px !important;
  margin-right: 10px;
  margin-top: 5px;
}