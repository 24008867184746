.inputCurrency {
  width: 100%;
  color: #575757;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e5ea;
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
}

.inputCurrency:focus,
.inputCurrency:hover {
  border-color: #1675e0;
  outline: 0;
}
